<script setup lang="ts">
const { t } = useT();
const { open } = useAppModals();
const loginGuard = useLoginGuard();
const isGuest = useIsGuest();
const { data, durationLeft, firstItemData, firstItemTimer } = useScretchCardData({ immediate: !isGuest.value });

const buttonName = computed(() => {
	if (isGuest.value) {
		return t("Create an Account");
	}
	if (firstItemData.value?.progress?.points === firstItemData.value?.progress?.pointsComplete) {
		return t("Find your treasure");
	}
	if (firstItemData.value.timer) {
		return firstItemTimer.value;
	}
	return t("Collect Free FC");
});

const handleCardClick = () => {
	loginGuard({
		success: () => {
			navigateTo("/magic-boxes", { external: true });
		},
		fail: () => open("LazyOModalSignup")
	});
};
const handleButtonClick = () => {
	loginGuard({
		success: () => {
			if (firstItemData.value?.progress?.points === firstItemData.value?.progress?.pointsComplete) {
				open("LazyOModalMagicBoxesSelection");
				return;
			}
			window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
		},
		fail: () => open("LazyOModalSignup")
	});
};
</script>

<template>
	<ACard
		class="card-magic-boxes"
		width="292px"
		height="380px"
		:imgAttrs="{
			width: 292,
			height: 380,
			src: '/nuxt-img/magic-boxes/promo-bg.png'
		}"
		alt="scratch-card"
		@click="handleCardClick"
	>
		<AText class="countdown" :modifiers="['uppercase', 'center']" as="div">
			<template v-if="isGuest">{{ t("Register to Play") }}</template>
			<template v-else>
				{{ t("Ends in") }}&nbsp;<b>{{ durationLeft }}</b>
			</template>
		</AText>

		<template #bottom>
			<div class="text">
				<div class="prizes">
					<MPrizeFund variant="coins" icon="coins" is-svg :iconSize="28">
						<AText variant="tirana" class="prize-text" :modifiers="['semibold']">
							{{ numberFormat(Number(data?.prize?.coins || 750000)) }}
						</AText>
					</MPrizeFund>
					<MPrizeFund variant="entries" icon="entries" is-svg :iconSize="28">
						<AText variant="tirana" class="prize-text" :modifiers="['semibold']">
							{{ numberFormat(Number(data?.prize?.entries || 7500)) }}
						</AText>
						<AText class="free" :modifiers="['semibold', 'uppercase']"> {{ t("for free") }}</AText>
					</MPrizeFund>
				</div>
				<AText class="text-caracas" variant="turin" :modifiers="['bold', 'center', 'uppercase']" as="div">
					{{ t("Daily Magic boxes") }}
				</AText>
			</div>

			<MMagicBoxesChests />

			<AButton
				class="button"
				variant="primary"
				size="xl"
				:modifiers="[!!firstItemData?.timer ? 'disabled' : '']"
				@click.stop="handleButtonClick"
			>
				<AText variant="turin" :modifiers="['medium']">
					{{ buttonName }}
				</AText>
			</AButton>
		</template>
	</ACard>
</template>

<style scoped lang="scss">
.card-magic-boxes {
	width: 292px;
	height: 380px;
	.text {
		margin-bottom: 120px;
	}
	.chests {
		position: absolute;
		bottom: -90px;
		left: 50%;
		transform: translateX(-50%) scale(0.4);
	}
}

.countdown {
	position: relative;
	padding: gutter(1);
	width: calc(100% - 24px);
	height: 34px;
	background: rgba(var(--chattogram-rgb), 0.8);
	margin: 0 auto;
	border-top-left-radius: 12px;
	border-top-right-radius: 12px;
	z-index: 3;
}
.prizes {
	text-align: center;
	.free {
		max-width: 36px;
	}
}
button.primary {
	width: 100%;
	position: relative;
	z-index: 2;
}
</style>
